import React, { useState } from "react";
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Settings as SettingsIcon,
  Handyman as HandymanIcon,
  Dashboard as DashboardIcon,
  FileOpen as FileOpenIcon,
  Email as EmailIcon,
  Backup as BackupIcon,
  Assessment as AssessmentIcon,
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
  Camera as CameraIcon,
  Build as BuildIcon,
  Task as TaskIcon,
  Pin as PinIcon,
  PushPinOutlined as UnpinIcon,
  Api as ApiIcon,
  AutoGraph as AutoGraphIcon,
  CompassCalibration as CompassCalibrationIcon,
  SettingsApplications as SettingsApplicationsIcon
} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
const drawerWidth = 240;

function MenuMMS({ setOpen, open }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [submenuState, setSubmenuState] = useState({});

  // Function to toggle submenu
  const handleSubmenuToggle = (menuName) => {
    setSubmenuState((prev) => ({
      ...prev,
      [menuName]: !prev[menuName],
    }));
  };

  // Menu configuration
  const menuItems = [
    { 
      name: 'Dashboard', icon: <SettingsIcon />, path: '/dashboard' 
    },
    { 
      name: 'Logs', icon: <FileOpenIcon />, path: '/logs' 
    },
    { 
      name: 'Work Order', icon: <HandymanIcon />, path: '/workorder',
      submenu: [
        { name: 'Create Work Order', path: '/workorder' },
        { name: 'View Work Orders', path: '/workorder/view' },
      ],
    },
    { 
      name: 'Warrenty', icon: <ApiIcon />, path: '/warrenty' 
    },
    
    { 
      name: 'Maintenance', icon: <BuildIcon />, path: '/maintenance' 
    },
    { 
      name: 'Calibration Order', icon: <AutoGraphIcon />, path: '/calibrationOrder' 
    },
    { 
      name: 'Critical Spare List', icon: <BuildIcon />, path: '/criticalSpareList' 
    },
    { 
      name: 'Task', icon: <TaskIcon />, path: '/task' 
    },
    { 
      name: 'Email', icon: <EmailIcon />, path: '/email' 
    },
    { 
      name: 'DB Backup', icon: <BackupIcon />, path: '/backup' 
    },
    { 
      name: 'Configure', icon: <DashboardIcon />, path: '/configure' 
    },
    { 
      name: 'Near Miss', icon: <CompassCalibrationIcon />, path: '/nearmiss' 
    },
    { 
      name: 'Reports', icon: <AssessmentIcon />, path: '/reports',
      submenu: [
        { name: 'Factory Licence', path: '/reports/daily' },
        { name: 'Lifting Tools and Tackles', path: '/reports/weekly' },
      ],
    },
    { 
      name: 'ERT', icon: <AssessmentIcon />, path: '/ert',
      
    },
    { 
      name: 'Settings', icon: <SettingsApplicationsIcon />, path: '/settings',
      submenu: [
        { name: 'User Settings', path: '/settings/user' },
        { name: 'App Settings', path: '/settings/app' },
      ],
    }
  ];

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const [pinned, setPinned] = useState(false);

  const handleDrawerToggle = () => {
    if (pinned) {
      setPinned(false);
      setOpen(false);
    } else {
      setOpen(!open);
    }
  };

  const handlePinToggle = () => {
    setPinned(!pinned);
    setOpen(!pinned);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  return (
    <Drawer variant="permanent" open={open} className='bg-sky-500 mms-left-menu'>
      <DrawerHeader className='bg-sky-500'>
        <IconButton onClick={handleDrawerToggle}>
          {pinned ? <UnpinIcon /> : <MenuIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List className='bg-sky-500 text-white'>
        {menuItems.map((menuItem) => (
          <React.Fragment key={menuItem.name}>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => {
                  menuItem.submenu ? handleSubmenuToggle(menuItem.name) : handleMenuClick(menuItem.path);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                >
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText primary={menuItem.name} sx={{ opacity: open ? 1 : 0 }} />
                {menuItem.submenu && (
                  submenuState[menuItem.name] ? <ExpandLess /> : <ExpandMore />
                )}
              </ListItemButton>
            </ListItem>

            {/* Submenu Rendering */}
            {menuItem.submenu && (
              <Collapse in={submenuState[menuItem.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menuItem.submenu.map((subItem) => (
                    <ListItemButton 
                      key={subItem.name} 
                      sx={{ pl: 4 }} 
                      onClick={() => handleMenuClick(subItem.path)}
                    >
                      <ListItemIcon>
                        <CameraIcon /> {/* Use any icon for submenu items */}
                      </ListItemIcon>
                      <ListItemText primary={subItem.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
      <List>
        <ListItem button onClick={handlePinToggle}>
          <ListItemIcon>{pinned ? <UnpinIcon /> : <PinIcon />}</ListItemIcon>
          <ListItemText primary={pinned ? 'Unpin Menu' : 'Pin Menu'} />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default MenuMMS;
